import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './routes/Home';
import About from './routes/About';
import Cart from './routes/Cart';
import Details from './routes/Details';
import Success from './routes/Success';
import Nav from './ui/Nav';
import Layout from './ui/Layout';
import * as React from 'react';
import { useEffect } from 'react';
import { FLAVORS } from './app/flavorSlice';
import Fruits from './assets/images/fruits.jpg';
import Beans from './assets/images/beans.jpg';
import Pen from './assets/images/pen.png';
import Signature from './assets/images/signature.png';
import useScreenSize from './utils/screen-size';
import NextIcon from './assets/images/next.svg';
import BackIcon from './assets/images/back.svg';
import Loading from './components/Loading';
import Unwrapped from './assets/images/unwrapped.png';

function App() {
	const screenSize = useScreenSize();
	const [allImagesLoaded, setImagesLoaded] = React.useState(false);

	useEffect(() => {
		const loadImage = (image) => {
			return new Promise((resolve, reject) => {
				const loadImg = new Image();
				loadImg.src = image;
				loadImg.onload = () => resolve(image);
				loadImg.onerror = (err) => reject(err);
			});
		};

		const imagePromises = FLAVORS.map((flavor) => {
			return loadImage(flavor.image);
		});
		const miniImagePromises = FLAVORS.map((flavor) => {
			return loadImage(flavor.imageMini);
		});
		const desktopImagePromises = FLAVORS.map((flavor) => {
			return loadImage(flavor.imageDesktop);
		});
		const detailsImagePromises = FLAVORS.map((flavor) => {
			return loadImage(flavor.imageDetails);
		});
		const aboutImagePromises = [
			Pen,
			Fruits,
			Signature,
			Beans,
			NextIcon,
			BackIcon,
			Unwrapped,
		].map((image) => {
			return loadImage(image);
		});

		// Load just first image that is shown on site
		const homePageImagePromise =
			screenSize.width > 800 ? imagePromises[0] : desktopImagePromises[0];

		Promise.all([homePageImagePromise])
			.then(() => {
				setImagesLoaded(true);
			})
			.catch((err) => console.log('Failed to load images', err));

		// Load the rest of all the images
		const allPromises = imagePromises
			.concat(miniImagePromises)
			.concat(desktopImagePromises)
			.concat(aboutImagePromises)
			.concat(detailsImagePromises);

		Promise.all(allPromises)
			.then(() => {
				// Now all site is loaded
			})
			.catch((err) => console.log('Failed to load all images', err));
	}, [screenSize.width]);

	const AppToRender = () => (
		<div>
			<Nav />
			<Layout>
				<Routes>
					<Route
						path="/success"
						element={<Success />}
					/>
					<Route
						path="/about"
						element={<About />}
					/>
					<Route
						path="/cart"
						element={<Cart />}
					/>
					<Route
						path="/details"
						element={<Details />}
					/>
					<Route
						path="/"
						exact
						element={<Home />}
					/>
				</Routes>
			</Layout>
		</div>
	);

	return allImagesLoaded ? <AppToRender /> : <Loading />;
}

export default App;
