import './Success.scss';
import Unwrapped from '../assets/images/unwrapped.png';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import { emptyCart } from '../app/flavorSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

function Success() {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(emptyCart());
	}, [dispatch]);

	return (
		<div className="SuccessRoute">
			<div className="SuccessCenteredColumn">
				<img
					className="UnwrappedImage"
					alt="UnwrappedChocolate"
					src={Unwrapped}
				/>
				<div>
					<div className="SuccessTitle">THANKS FOR YOUR ORDER!</div>
					<div className="SuccessSubtitle">
						A confirmation has been sent to your email address.
					</div>
					<Link
						className="NoUnderline SuccessButton"
						to="/"
					>
						<Button
							className="InlineButton"
							style={{
								width: '100%',
								maxWidth: '400px',
								fontFamily: 'ChaparralProItalic',
								textTransform: 'none',
								fontSize: '22px',
								backgroundColor: '#080451',
								color: '#FFD6CA',
							}}
						>
							Continue shopping
						</Button>
					</Link>
				</div>
				<div className="ExtraDiv">
					<Link
						className="NoUnderline ButtonContainer"
						to="/"
					>
						<Button
							className="FooterButton MobileButton"
							style={{
								position: 'fixed',
								left: '0',
								bottom: '0px',
								width: '100%',
								fontFamily: 'ChaparralProItalic',
								textTransform: 'none',
								fontSize: '22px',
								borderRadius: '0px',
								backgroundColor: '#080451',
								color: '#FFD6CA',
							}}
						>
							Continue shopping
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
}

export default Success;
