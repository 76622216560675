import React, { Component } from 'react';
import './Layout.scss';

class Layout extends Component {
	state = {
		showSideDrawer: false,
	};
	sideDrawerClosedhandler = () => {
		this.setState({ showSideDrawer: false });
	};
	sideDrawerToggleHandler = () => {
		this.setState((prevState) => {
			return { showSideDrawer: !prevState.showSideDrawer };
		});
	};
	render() {
		return <main className="Layout">{this.props.children}</main>;
	}
}

export default Layout;
