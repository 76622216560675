import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function VaughtsSnackbar({ props }) {
	return (
		<div>
			<Snackbar
				open={props.isToastOpen}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				autoHideDuration={props.autoHideDuration}
				onClose={props.closeToast}
				className="SnackBar"
				sx={{
					width: '100%',
					left: '0',
				}}
			>
				<Alert
					onClose={props.closeToast}
					severity="success"
					className="Snack"
					sx={{
						width: '100%',
						top: '72px',
						fontFamily: 'Gotham',
						backgroundColor: props.buttonColor,
						color: props.buttonTextColor,
						textAlign: 'center',
						borderRadius: '0',
						boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.50)',
						'& .MuiAlert-icon': {
							display: 'none',
						},
						'& .MuiAlert-action': {
							display: 'none',
						},
						'& .MuiAlert-message': {
							width: '100%',
						},
					}}
				>
					{props.message}
				</Alert>
			</Snackbar>
		</div>
	);
}
