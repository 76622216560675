import * as React from 'react';
import { Link } from 'react-router-dom';
import './Chocolate.scss';
import useScreenSize from '../utils/screen-size';
// import { addGestures } from '../utils/gestures';
// import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { moveCursorLeft, moveCursorRight } from '../app/flavorSlice';
import { ReactComponent as Next } from '../assets/images/next.svg';
import { ReactComponent as Back } from '../assets/images/back.svg';
import Button from '@mui/material/Button';
import ChocolatePercentage from './ChocolatePercentage';

export default function Chocolate({ flavor }) {
	const [isLoaded, setLoaded] = React.useState(false);

	const screenSize = useScreenSize();
	const dispatch = useDispatch();
	const moveLeft = () => {
		setLoaded(false);
		dispatch(moveCursorLeft());
	};

	const moveRight = () => {
		setLoaded(false);
		dispatch(moveCursorRight());
	};

	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// const onLeftSwipe = useCallback(() => {
	// 	moveLeft();
	// });

	// // eslint-disable-next-line react-hooks/exhaustive-deps
	// const onRightSwipe = useCallback(() => {
	// 	moveRight();
	// });

	// useEffect(() => {
	// 	addGestures(onLeftSwipe, onRightSwipe);
	// });

	return (
		<div
			className="ChocolateRoute"
			style={{
				backgroundColor: flavor.backgroundColor,
				color: flavor.titleTextColor,
			}}
		>
			<div
				style={{ display: isLoaded ? '' : 'none' }}
				className="ChocolatePage"
			>
				<Back
					className="CarouselButton CarouselButtonLeft CarouselButtonDesktop"
					stroke={flavor.titleTextColor}
					onClick={moveLeft}
				/>
				<div className="DesktopCarouselInterior">
					<div className="ChocolateImageContainer">
						<img
							key={flavor.priceId}
							alt="ChocolateBar"
							className="ChocolateImage"
							onLoad={() => setLoaded(true)}
							src={screenSize.width > 800 ? flavor.imageDesktop : flavor.image}
						/>
						{/* Put <Back/> here because of animation glitch */}
						<Back
							className="CarouselButton CarouselButtonLeft CarouselButtonMobile"
							stroke={flavor.titleTextColor}
							onClick={moveLeft}
						/>
						<Next
							className="CarouselButton CarouselButtonRight CarouselButtonMobile"
							stroke={flavor.titleTextColor}
							onClick={moveRight}
						/>
					</div>
					{/* <CarouselDots
					flavor={flavor}
					moveLeft={moveLeft}
					moveRight={moveRight}
				/> */}
					<div className="ChocolateFooter">
						<div className="ChocolateTextSection">
							<ChocolatePercentage
								flavor={flavor}
								extraClass="ChocolatePercentageHome"
							/>
							<div className="ChocolateTitle ChocolateTitleHome">
								{flavor.title}
							</div>
							<div className="ChocolateSubtitleText">{flavor.subtitle}</div>
							<Link
								className="NoUnderline"
								to="details"
							>
								<Button
									className="InlineButton HomeButton"
									style={{
										width: '100%',
										maxWidth: '200px',
										fontFamily: 'ChaparralProItalic',
										textTransform: 'none',
										fontSize: '22px',
										backgroundColor: flavor.buttonColor,
										color: flavor.buttonTextColor,
									}}
								>
									Try it
								</Button>
							</Link>
						</div>
					</div>
				</div>
				<Next
					className="CarouselButton CarouselButtonRight CarouselButtonDesktop"
					stroke={flavor.titleTextColor}
					onClick={moveRight}
				/>
			</div>
			<Link
				className="NoUnderline ButtonContainer"
				to="details"
			>
				<Button
					className="FooterButton MobileButton"
					style={{
						position: 'fixed',
						bottom: '0px',
						width: '100%',
						fontFamily: 'ChaparralProItalic',
						textTransform: 'none',
						fontSize: '22px',
						borderRadius: '0px',
						backgroundColor: flavor.buttonColor,
						color: flavor.buttonTextColor,
					}}
				>
					Try it
				</Button>
			</Link>
		</div>
	);
}
