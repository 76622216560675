import { configureStore } from '@reduxjs/toolkit';
import flavorsReducer from './flavorSlice';

const store = configureStore({
	reducer: {
		flavorReducer: flavorsReducer,
	},
});

store.subscribe(() => {
	// Cache the selected index in local storage
	localStorage.setItem(
		'selectedIndex',
		store.getState().flavorReducer.selectedIndex
	);
	// Only put the quantity into local storage instead of caching all image paths as well
	store.getState().flavorReducer.flavors.forEach((flavor) => {
		localStorage.setItem(flavor.title, flavor.quantity);
	});
});

export default store;
