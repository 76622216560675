import './Details.scss';
import { useSelector, useDispatch } from 'react-redux';
import { addToCart } from '../app/flavorSlice';
import ChocolatePercentage from '../components/ChocolatePercentage';
import Button from '@mui/material/Button';
import * as React from 'react';
import VaughtsSnackbar from '../ui/VaughtsSnackbar';

function Details() {
	const [isToastOpen, setToastOpen] = React.useState(false);
	const [isErrorToastOpen, setErrorToastOpen] = React.useState(false);

	const selectedFlavor = useSelector(
		(state) => state.flavorReducer.selectedFlavor
	);
	const dispatch = useDispatch();

	const addToCartLocal = () => {
		if (selectedFlavor.outOfStock) {
			setErrorToastOpen(true);
			return;
		}
		setToastOpen(true);
		dispatch(addToCart(1));
	};

	const closeToast = (_event, _reason) => {
		setToastOpen(false);
	};

	return (
		<div
			className="Details"
			style={{
				backgroundColor: selectedFlavor.backgroundColor,
				color: selectedFlavor.titleTextColor,
			}}
		>
			<VaughtsSnackbar
				props={{
					isToastOpen: isErrorToastOpen,
					closeToast: () => setErrorToastOpen(false),
					buttonColor: selectedFlavor.buttonColor,
					buttonTextColor: selectedFlavor.buttonTextColor,
					autoHideDuration: 5000,
					message:
						'Sorry, we are out of stock of this flavor! Check back soon.',
				}}
			/>
			<VaughtsSnackbar
				props={{
					isToastOpen: isToastOpen,
					closeToast: closeToast,
					buttonColor: selectedFlavor.buttonColor,
					buttonTextColor: selectedFlavor.buttonTextColor,
					autoHideDuration: 2000,
					message: 'New item added to your cart!',
				}}
			/>
			<div className="DetailsHeroContainer">
				<div className="DetailsHero">
					<img
						alt="ChocolateBar"
						className="DetailsImage"
						src={selectedFlavor.imageDetails}
					/>
					<div
						className="ChocolateTextSection DetailsChocolateTextSection"
						style={{
							backgroundColor: selectedFlavor.backgroundColor,
						}}
					>
						<ChocolatePercentage flavor={selectedFlavor} />
						<div className="ChocolateTitle">{selectedFlavor.title}</div>
						<div className="DetailsTextSection">
							<div>
								<div className="ChocolateSubtitle">NOTES OF</div>
								<div
									style={{
										color: selectedFlavor.detailsTextColor,
									}}
									className="ChocolateNotes"
								>
									{selectedFlavor.notes.map((note) => (
										<div key={note}>
											{note}
											<br />
										</div>
									))}
								</div>
							</div>
						</div>
						<div
							className="ChocolatePrice"
							style={{
								borderTop: `solid ${selectedFlavor.titleTextColor} 1px`,
								borderBottom: `solid ${selectedFlavor.titleTextColor} 1px`,
							}}
						>
							<div className="ChocolateWeightAndPrice">
								$10.00 |<span className="ChocolateWeight"> 55 g</span>
							</div>
						</div>

						<Button
							className="InlineButton"
							style={{
								backgroundColor: selectedFlavor.buttonColor,
								color: selectedFlavor.buttonTextColor,
								width: '100%',
								maxWidth: '275px',
								marginTop: '2rem !important',
								fontFamily: 'ChaparralProItalic',
								textTransform: 'none',
								fontSize: '22px',
								borderRadius: '4px',
							}}
							size="large"
							onClick={addToCartLocal}
							variant="extended"
						>
							{selectedFlavor.outOfStock ? 'Out of stock' : 'Add to cart'}
						</Button>
					</div>
				</div>
			</div>
			<div
				style={{
					backgroundColor: selectedFlavor.backgroundColor,
					color: selectedFlavor.detailsTextColor,
				}}
				className="DetailsExplained"
			>
				{selectedFlavor.details}
			</div>
			<div className="IngredientsSection">
				<div className="IngredientsTextSection">
					<div className="IngredientsTitle">INGREDIENTS</div>
					<div className="IngredientsLabel">EN</div>
					<div className="IngredientsHeader">
						{selectedFlavor.ingredientsTitle}{' '}
						<span className="IngredientsSubtitle">
							{selectedFlavor.ingredientsSubtitle}
						</span>
					</div>
					<div className="IngredientsLabel">DK</div>
					<div className="IngredientsHeader">
						{selectedFlavor.ingredientsTitleDanish}{' '}
						<span className="IngredientsSubtitle">
							{selectedFlavor.ingredientsSubtitleDanish}
						</span>
					</div>
				</div>
				<div className="NutritionFactsSection">
					<div className="IngredientsTitle">
						NUTRITION FACTS{' '}
						<span className="IngredientsTitleLittle">(PER 55g)</span>
					</div>
					<table className="IngredientsNutritionTable">
						<tbody>
							{selectedFlavor.nutritionFacts.map((fact) => {
								return (
									<tr key={fact.label}>
										<td>{fact.label}</td>
										<td className="IngredientsNutritionFact">
											{' ' + fact.description}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
			</div>
			<Button
				className="FooterButton MobileButton"
				style={{
					backgroundColor: selectedFlavor.buttonColor,
					color: selectedFlavor.buttonTextColor,
					position: 'fixed',
					bottom: '0px',
					width: '100%',
					fontFamily: 'ChaparralProItalic',
					textTransform: 'none',
					fontSize: '22px',
					borderRadius: '0px',
				}}
				size="large"
				onClick={addToCartLocal}
				variant="extended"
			>
				Add to cart
			</Button>
		</div>
	);
}

export default Details;
