export default function ChocolatePercentage({ flavor, extraClass }) {
	if (flavor.percentage) {
		return (
			<div className={'ChocolatePercentage ' + extraClass}>
				{flavor.percentage}
			</div>
		);
	} else {
		return null;
	}
}
