import * as React from 'react';
import './Cart.scss';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	increaseQuantity,
	decreaseQuantity,
	calculateTotals,
	removeFromCart,
} from '../app/flavorSlice';
import { loadStripe } from '@stripe/stripe-js';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Plus } from '../assets/images/plus.svg';
import { ReactComponent as Minus } from '../assets/images/minus.svg';
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

function Cart() {
	const flavors = useSelector((state) => state.flavorReducer.flavors);
	const shippingTotal = useSelector(
		(state) => state.flavorReducer.shippingTotal
	);
	const shippingPriceId = useSelector(
		(state) => state.flavorReducer.shippingPriceId
	);
	const subtotal = useSelector((state) => state.flavorReducer.subtotal);
	const total = useSelector((state) => state.flavorReducer.total);
	const dispatch = useDispatch();

	const [isModalOpen, setModalOpen] = React.useState(false);
	const [errorFlavor, setErrorFlavor] = React.useState('');

	useEffect(() => {
		dispatch(calculateTotals());
	}, [dispatch]);

	const flavorsInCart = flavors.filter((flavor) => {
		return flavor.quantity > 0;
	});

	const increase = (flavor) => {
		dispatch(
			increaseQuantity({
				flavor: flavor,
				amount: 1,
			})
		);
		dispatch(calculateTotals());
	};

	const decrease = (flavor) => {
		dispatch(
			decreaseQuantity({
				flavor: flavor,
				amount: 1,
			})
		);
		dispatch(calculateTotals());
	};

	const removeFlavorFromCart = (flavor) => {
		dispatch(removeFromCart({ flavor }));
		dispatch(calculateTotals());
	};

	const EmptyCart = () => {
		return (
			<div className="EmptyCart">
				<div>
					Your cart is empty.
					<div className="ShopButton">
						<Link
							className="NoUnderline"
							to="/"
						>
							<Button
								style={{
									backgroundColor: '#1b2797',
									color: 'white',
									bottom: '0',
									width: '100%',
									fontFamily: 'ChaparralProItalic',
									textTransform: 'none',
									fontSize: '22px',
									height: '50px',
									borderRadius: '50px',
								}}
							>
								Shop
							</Button>
						</Link>
					</div>
				</div>
			</div>
		);
	};

	const Divider = () => {
		return <div className="Divider"></div>;
	};

	const redirectToCheckout = async () => {
		const flavorsToPurchase = flavors.filter((flavor) => {
			return flavor.quantity > 0;
		});
		const lineItems = flavorsToPurchase.map((flavor) => {
			return {
				price: flavor.priceId,
				quantity: flavor.quantity,
			};
		});

		const shippingPrice = {
			price: shippingPriceId,
			quantity: 1,
		};

		lineItems.push(shippingPrice);

		const checkoutOptions = {
			shippingAddressCollection: { allowedCountries: ['US', 'DK'] },
			lineItems: lineItems,
			mode: 'payment',
			successUrl: `${window.location.origin}/success`,
			cancelUrl: `${window.location.origin}/cart`,
		};

		// TODO(@trevorfrese) put key somewhere else
		const stripe = await loadStripe(
			'pk_live_51NMvBgDaV1AKvORFcTpG8DfoN1Oq91A9gdgx7yVtu23FLwXbPpdj1dOR3JgZa2QHnKupE3fGoGPOziGMQQn97tbi00L8iXU6gp'
		);

		try {
			const { error } = await stripe.redirectToCheckout(checkoutOptions);
			console.log('Stripe checkout error', error);
		} catch (error) {
			const stringMatch = error.message.match(/price_\w+/);
			if (!stringMatch) {
				return;
			}
			const priceId = stringMatch[0];
			if (priceId) {
				const flavor = flavors.find((flavor) => {
					return flavor.priceId === priceId;
				});
				removeFlavorFromCart(flavor);
				setErrorFlavor(flavor.title);
			}
			setModalOpen(true);
		}
	};

	const flavorsList = flavorsInCart.map((flavor, index) => {
		return (
			<div
				key={flavor.priceId}
				className="CartRowWithDivider"
			>
				<div className="CartRow">
					<img
						alt="CartIcon"
						className="CartRowImage"
						src={flavor.imageMini}
					/>
					<div className="CartRowDetails">
						<div className="CartRowText">
							<div className="CartTitleAndClose">
								<div>{flavor.title}</div>
								<CloseIcon
									className="CloseIcon"
									onClick={() => removeFlavorFromCart(flavor)}
								/>
							</div>
							<div className="CartRowPrice">
								${(flavor.quantity * flavor.price).toFixed(2)}
							</div>
						</div>
						<div>
							<div className="CartRowControls">
								<Button
									aria-label="decrease"
									className="CartRowQuantityButton"
									disabled={flavor.quantity === 1}
									onClick={() => decrease(flavor)}
								>
									<Minus />
								</Button>
								<div className="CartRowQuantity">{flavor.quantity}</div>
								<Button
									size="small"
									aria-label="increase"
									className="CartRowQuantityButton"
									disabled={flavor.quantity === 10}
									onClick={() => increase(flavor)}
								>
									<Plus />
								</Button>
							</div>
						</div>
					</div>
				</div>
				{flavorsInCart.length > 1 && index !== flavorsInCart.length - 1 && (
					<div className="DividerRow">
						<Divider />
					</div>
				)}
			</div>
		);
	});
	const checkoutContainer = (
		<div className="CheckoutContainer">
			<Stack
				height={'100%'}
				direction="column"
				justifyContent="space-around"
				spacing={2}
				className="CartStack"
			>
				{flavorsList}
				<div className="SpacerRow"></div>
			</Stack>
			<div className="CheckoutRow">
				<div className="Pricing">
					<div className="PricingSubtotals">
						<div className="PricingTitleSection">
							<div className="PricingTitle">Order Summary</div>
							<Divider />
						</div>
						<div className="PricingSubtotal">
							<div>Subtotal:</div>
							<div>{'$' + subtotal}</div>
						</div>
						<div className="PricingSubtotal PricingSubtotalLast">
							<div>Shipping:</div>
							<div>{'$' + shippingTotal}</div>
						</div>
						<Divider />
					</div>
					<div className="PricingTotal">
						<div>Total:</div>
						<div>{'$' + total}</div>
					</div>
				</div>
				<Button
					onClick={redirectToCheckout}
					className="FooterButton"
					style={{
						backgroundColor: '#1b2797',
						color: 'white',
						bottom: '0',
						width: '100%',
						fontFamily: 'ChaparralProItalic',
						textTransform: 'none',
						fontSize: '22px',
						height: '67px',
						borderRadius: '0px',
					}}
				>
					Checkout
				</Button>
			</div>
		</div>
	);

	const cartDisplay =
		flavorsInCart.length > 0 ? checkoutContainer : <EmptyCart />;

	return (
		<div className="CartRoute">
			<Modal
				open={isModalOpen}
				onClick={() => setModalOpen(false)}
				onClose={() => setModalOpen(false)}
			>
				<Box
					className="ErrorModal"
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '300px',
						backgroundColor: '#1b2797',
						color: 'white',
						fontFamily: 'Gotham-Light',
						textTransform: 'none',
						boxShadow: 24,
						p: 4,
					}}
				>
					<h1 className="ErrorTitle">We're sorry!</h1>
					<p className="ErrorDescription">
						Our chocolate is flying off the shelves!
						<br /> We're out of stock of {errorFlavor}.
					</p>
					<Button
						style={{
							backgroundColor: '#080451',
							color: '#eee',
							bottom: '0',
							width: '100%',
							fontFamily: 'ChaparralProItalic',
							textTransform: 'none',
							fontSize: '1.25rem',
							height: '55px',
							borderRadius: '0px',
						}}
					>
						Continue Shopping
					</Button>
				</Box>
			</Modal>
			<div className="CartRouteContainer">{cartDisplay}</div>
		</div>
	);
}

export default Cart;
