import { createSlice } from '@reduxjs/toolkit';
import Danish from '../assets/images/danish.png';
import DanishDesktop from '../assets/images/danish-desktop.png';
import DanishDetails from '../assets/images/danish-details.png';
import DanishMini from '../assets/images/danish-mini.svg';
import Dark from '../assets/images/dark.png';
import DarkDesktop from '../assets/images/dark-desktop.png';
import DarkDetails from '../assets/images/dark-details.png';
import DarkMini from '../assets/images/dark-mini.svg';
import DarkMilk from '../assets/images/darkmilk.png';
import DarkMilkDesktop from '../assets/images/darkmilk-desktop.png';
import DarkMilkDetails from '../assets/images/darkmilk-details.png';
import DarkMilkMini from '../assets/images/darkmilk-mini.svg';
import Texas from '../assets/images/texas.png';
import TexasDesktop from '../assets/images/texas-desktop.png';
import TexasDetails from '../assets/images/texas-details.png';
import TexasMini from '../assets/images/texas-mini.svg';
import Raspberry from '../assets/images/raspberry.png';
import RaspberryDesktop from '../assets/images/raspberry-desktop.png';
import RaspberryDetails from '../assets/images/raspberry-details.png';
import RaspberryMini from '../assets/images/raspberry-mini.svg';

export const FLAVORS = [
	{
		backgroundColor: '#1B2797',
		buttonColor: '#080451',
		buttonTextColor: '#F3FF4C',
		detailsTextColor: '#FFFFFF',
		details:
			'This classic dark chocolate snaps with a sharp bite at the beginning that mellows out into a light and fruity classic full bodied dark		chocolate. The beans in this bar are certified organic and come from a region in Guatemala near the crystal clear Lachuá Cenote. The area around the Laguna Lachuá national park is farmed by many different Q’eqchi’ Maya families who own their own small farms. Many of these farmers live remotely and rely on farming as their primary income. The beans are sorted by hand, and dried in the shade.',
		image: Dark,
		imageDesktop: DarkDesktop,
		imageDetails: DarkDetails,
		imageMini: DarkMini,
		ingredientsTitle: 'Organic cocoa beans, sugar, cocoa butter',
		ingredientsSubtitle:
			'- 70% cocoa content - May contain trace amounts of nuts, milk or wheat',
		ingredientsTitleDanish: 'Økologisk kakaobønner, sukker, kakaosmør',
		ingredientsSubtitleDanish:
			'- 70% kakaoindhold - Kan indeholde spormængder af nødder, mælk eller hvede',
		nutrition: {
			calories: '310 cal',
			fat: '21g',
			saturatedFat: '12.6g',
			cholesterol: '0mg',
			carbs: '25g',
			fiber: '6g',
			sugar: '17g',
			protein: '4g',
			sodium: '0mg',
			iron: '4.7mg',
			potassium: '0mg',
			vitaminC: '0mg',
			vitaminE: '0mg',
			calcium: '0mg',
		},
		nutritionFacts: [
			{
				description: '310 cal',
				label: 'Calories / Energi',
			},
			{
				description: '21g',
				label: 'Fat / Fedt',
			},
			{
				description: '12.6g',
				label: 'Saturated fat / mættet fedt',
			},
			{
				description: '0mg',
				label: 'Cholesterol / Kolesterol',
			},
			{
				description: '25g',
				label: 'Carbohydrates / kulhydrat',
			},
			{
				description: '6g',
				label: 'Dietary Fiber',
			},
			{
				description: '17g',
				label: 'Sugars / Sukker',
			},
			{
				description: '4g',
				label: 'Protein',
			},
			{
				description: '4.7mg',
				label: 'Iron / Jern',
			},
		],
		outOfStock: false,
		percentage: '70%',
		price: 10.0,
		priceId: 'price_1NMvS3DaV1AKvORFXr6vuMBh',
		quantity: 0,
		notes: ['Raspberry', 'Cream', 'Cherry', 'Earth'],
		subtitle: 'FROM LACHUÁ, GUATEMALA',
		title: 'DARK CHOCOLATE',
		titleTextColor: '#F3FF4C',
	},
	{
		backgroundColor: '#D5C076',
		buttonColor: '#253DB8',
		buttonTextColor: '#E8DBA6',
		detailsTextColor: '#080451',
		details:
			'Dark milk combines the sweetness and creaminess of milk chocolate with the flavor complexity of dark chocolate. This dark milk tastes like a buttery chocolate chip banana bread. The beans come from one of the most diverse cocoa growing regions in the world in the tropical lowlands of southwest Venezuela. Cocoa trees have been cultivated here for thousands of years and over time have cross-pollinated to create new genetic varieties. The beans used in this bar were born from a cross of Criollo and Trinitario trees which resulted in a delicate but robust bean with a low level of astringency that gives it a beautifully buttery flavor.',
		image: DarkMilk,
		imageDesktop: DarkMilkDesktop,
		imageDetails: DarkMilkDetails,
		imageMini: DarkMilkMini,
		ingredientsTitle:
			'Cacao butter, cane sugar, milk powder, freezedried banana',
		ingredientsSubtitle:
			'- 60% cocoa content - May contain trace amounts of nuts, milk or wheat',
		ingredientsTitleDanish: 'Kakaosmør, sukker, mælkepulver, frysetørret banan',
		ingredientsSubtitleDanish:
			'- 60% kakaoindhold - Kan indeholde spormængder af nødder, mælk eller hvede',
		nutrition: {
			calories: '300 cal',
			fat: '19g',
			saturatedFat: '11g',
			cholesterol: '0mg',
			carbs: '28g',
			fiber: '5g',
			sugar: '19g',
			protein: '4.1g',
			iron: '90mg',
			potassium: '90mg',
			vitaminC: '0mg',
			vitaminE: '0mg',
			calcium: '40mg',
		},
		nutritionFacts: [
			{
				description: '300 cal',
				label: 'Calories / Energi',
			},
			{
				description: '19g',
				label: 'Fat / Fedt',
			},
			{
				description: '11g',
				label: 'Saturated fat / mættet fedt',
			},
			{
				description: '0mg',
				label: 'Cholesterol / Kolesterol',
			},
			{
				description: '28g',
				label: 'Carbohydrates / kulhydrat',
			},
			{
				description: '5g',
				label: 'Dietary Fiber',
			},
			{
				description: '19g',
				label: 'Sugars / Sukker',
			},
			{
				description: '4.1g',
				label: 'Protein',
			},
			{
				description: '90mg',
				label: 'Iron / Jern',
			},
			{
				description: '40mg',
				label: 'Calcium / Kalk',
			},
		],
		outOfStock: false,
		percentage: '60%',
		price: 10.0,
		priceId: 'price_1NMvVCDaV1AKvORFGLIs3WDp',
		quantity: 0,
		notes: ['Banana bread', 'Butter', 'Vanilla', 'Yellow cake'],
		subtitle: 'FROM SURE DEL LAGO, VENEZUELA',
		title: 'DARK MILK CHOCOLATE',
		titleTextColor: '#2740BE',
	},
	{
		backgroundColor: '#D29342',
		buttonColor: '#282380',
		buttonTextColor: '#FFBE68',
		detailsTextColor: '#080451',
		details:
			'Sea buckthorn is an orange, Nordic berry that grows along the coast on thorny bushes. It tastes similar to passion fruit or mango and is truly a superfood because one sea buckthorn berry has more vitamin C than a whole orange. This bright and tropical chocolate has notes of caramel and butterscotch.',
		image: Danish,
		imageDesktop: DanishDesktop,
		imageDetails: DanishDetails,
		imageMini: DanishMini,
		ingredientsTitle:
			'Cacao butter, cane sugar, non-fat milk powder, freezedried sea buckthorn berries',
		ingredientsSubtitle:
			'- 45% cocoa content - May contain trace amounts of nuts, milk or wheat',
		ingredientsTitleDanish:
			'kakaosmør, sukker, fedtfrit mælkepulver, frysetørret havthorn',
		ingredientsSubtitleDanish:
			'- 45% kakaoindhold - Kan indeholde spormængder af nødder, mælk eller hvede',
		nutrition: {
			calories: '300 cal',
			fat: '27g',
			saturatedFat: '15g',
			cholesterol: '<5mg',
			carbs: '23g',
			fiber: '2g',
			sugar: '21g',
			protein: '4g',
			iron: '0mg',
			potassium: '140mg',
			vitaminC: '64mg',
			vitaminE: '7.4mg',
			calcium: '100mg',
		},
		nutritionFacts: [
			{
				description: '300 cal',
				label: 'Calories / Energi',
			},
			{
				description: '27g',
				label: 'Fat / Fedt',
			},
			{
				description: '15g',
				label: 'Saturated fat / mættet fedt',
			},
			{
				description: '<5mg',
				label: 'Cholesterol / Kolesterol',
			},
			{
				description: '23g',
				label: 'Carbohydrates / kulhydrat',
			},
			{
				description: '2g',
				label: 'Dietary Fiber',
			},
			{
				description: '21g',
				label: 'Sugars / Sukker',
			},
			{
				description: '4g',
				label: 'Protein',
			},
			{
				description: '100mg',
				label: 'Calcium / Kalk',
			},
			{
				description: '7.4mg',
				label: 'Vitamin E',
			},
			{
				description: '64mg',
				label: 'Vitamin C',
			},
			{
				description: '140mg',
				label: 'Potassium / Kalium',
			},
		],
		outOfStock: false,
		percentage: undefined,
		price: 10.0,
		priceId: 'price_1NMvVqDaV1AKvORFTVVOniuq',
		quantity: 0,
		notes: ['Passion fruit', 'Toffee', 'Caramel', 'Orange blossom'],
		subtitle: 'VITAMIN C RICH CHOCOLATE',
		title: 'DANISH SEA BUCKTHORN',
		titleTextColor: '#282380',
	},
	{
		backgroundColor: '#c35243',
		buttonColor: '#E46F63',
		buttonTextColor: '#0C0102',
		detailsTextColor: '#3E030F',
		details:
			'The flavor of this bar comes only from the beans used to make it. These beans are grown on the eastern side of the island Java in Indonesia. The cacao orchards across East Java specialize in growing Java Criollo, also known as Red Java, for its distinct dark red cacao pods with white beans. This unique bar has a warm and vividly smokey flavor with the tang of a BBQ sauce. It evokes nights sitting next to the campfire under the expansive, deep Texas sky.',
		image: Texas,
		imageDesktop: TexasDesktop,
		imageDetails: TexasDetails,
		imageMini: TexasMini,
		ingredientsTitle: 'Organic cocoa beans, sugar, cocoa butter, milk powder',
		ingredientsSubtitle:
			'- 65% cocoa content - May contain trace amounts of nuts, milk or wheat',
		ingredientsTitleDanish:
			' Økologisk kakaobønner, sukker, kakaosmør, mælkepulver',
		ingredientsSubtitleDanish:
			'- 65% kakaoindhold - Kan indeholde spormængder af nødder, mælk eller hvede',
		nutrition: {
			calories: '300 cal',
			fat: '20g',
			saturatedFat: '12g',
			cholesterol: '0mg',
			carbs: '25g',
			fiber: '6g',
			sugar: '17g',
			protein: '4g',
			iron: '4.3mg',
			potassium: '50mg',
			vitaminC: '0mg',
			vitaminE: '0mg',
			calcium: '30mg',
		},
		nutritionFacts: [
			{
				description: '300 cal',
				label: 'Calories / Energi',
			},
			{
				description: '20g',
				label: 'Fat / Fedt',
			},
			{
				description: '12g',
				label: 'Saturated fat / mættet fedt',
			},
			{
				description: '0mg',
				label: 'Cholesterol / Kolesterol',
			},
			{
				description: '25g',
				label: 'Carbohydrates / kulhydrat',
			},
			{
				description: '6g',
				label: 'Dietary Fiber',
			},
			{
				description: '17g',
				label: 'Sugars / Sukker',
			},
			{
				description: '4g',
				label: 'Protein',
			},
			{
				description: '4.3mg',
				label: 'Iron / Jern',
			},
			{
				description: '30mg',
				label: 'Calcium / Kalk',
			},
			{
				description: '50mg',
				label: 'Potassium / Kalium',
			},
		],
		outOfStock: true,
		percentage: '65%',
		price: 10.0,
		priceId: 'price_1NMvW6DaV1AKvORFLVSBCiWU',
		quantity: 0,
		notes: ['Smoke', 'Mesquite wood', 'Leather', 'Honey'],
		subtitle: 'FROM JAVA, INDONESIA',
		title: 'TEXAS BBQ',
		titleTextColor: '#3E030F',
	},
	{
		backgroundColor: '#d35363',
		buttonColor: '#080451',
		buttonTextColor: '#FF6577',
		detailsTextColor: '#080451',
		details:
			'A sharp chocolate with bright notes of raspberry. It’s rich and creamy. The beans in this bar are certified organic and come from a region in Guatemala near the crystal clear Lachuá Cenote. The area around the national park is farmed by many different Q’eqchi’ Maya families. Many of these farmers own their own small farms and live quite remotely, relying on farming as their primary income. The beans are sorted by hand, and dried in the shade.',
		image: Raspberry,
		imageDesktop: RaspberryDesktop,
		imageDetails: RaspberryDetails,
		imageMini: RaspberryMini,
		ingredientsTitle:
			'Organic cocoa beans, sugar, cocoa butter, freezedried raspberries',
		ingredientsSubtitle:
			'- 70% cocoa content - May contain trace amounts of nuts, milk or wheat',
		ingredientsTitleDanish:
			'Økologisk kakaobønner, sukker, kakaosmør, frysetørrede hindbær',
		ingredientsSubtitleDanish:
			'- 70% kakaoindhold - Kan indeholde spormængder af nødder, mælk eller hvede',
		nutrition: {
			calories: '310 cal',
			fat: '21g',
			saturatedFat: '13g',
			cholesterol: '0mg',
			carbs: '24g',
			fiber: '7g',
			sugar: '15g',
			protein: '4g',
			iron: '4.7mg',
			potassium: '0mg',
			vitaminC: '6mg',
			vitaminE: '0mg',
			calcium: '10mg',
		},
		nutritionFacts: [
			{
				description: '310 cal',
				label: 'Calories / Energi',
			},
			{
				description: '21g',
				label: 'Fat / Fedt',
			},
			{
				description: '13g',
				label: 'Saturated fat / mættet fedt',
			},
			{
				description: '0mg',
				label: 'Cholesterol / Kolesterol',
			},
			{
				description: '24g',
				label: 'Carbohydrates / kulhydrat',
			},
			{
				description: '7g',
				label: 'Dietary Fiber',
			},
			{
				description: '15g',
				label: 'Sugars / Sukker',
			},
			{
				description: '4g',
				label: 'Protein',
			},
			{
				description: '4.7mg',
				label: 'Iron / Jern',
			},
			{
				description: '10mg',
				label: 'Calcium / Kalk',
			},
			{
				description: '6mg',
				label: 'Vitamin C',
			},
		],
		outOfStock: false,
		percentage: '70%',
		price: 10.0,
		priceId: 'price_1NMvVWDaV1AKvORFD5JgSLCc',
		quantity: 0,
		notes: ['Raspberry', 'Lemon', 'Earth', 'Rose'],
		subtitle: 'FROM LACHUÁ, GUATEMALA',
		title: 'BLACK RASPBERRY',
		titleTextColor: '#201D60',
	},
];

const getInitialState = () => {
	// Remove this for old users
	localStorage.removeItem('reduxState');
	// Seed initial quantity from local storage
	FLAVORS.forEach((flavor) => {
		const quantity = localStorage.getItem(flavor.title);
		if (quantity) {
			flavor.quantity = parseInt(quantity);
		} else {
			flavor.quantity = 0;
		}
	});

	let selectedIndex = 0;
	let selectedFlavor = FLAVORS[0];

	const selectedIndexFromStorage = localStorage.getItem('selectedIndex');
	if (selectedIndexFromStorage) {
		selectedIndex = parseInt(selectedIndexFromStorage);
		selectedFlavor = FLAVORS[selectedIndex];
	}

	return {
		flavors: FLAVORS,
		selectedIndex,
		selectedFlavor,
		shippingTotal: (5).toFixed(2),
		shippingPriceId: 'price_1NMvWMDaV1AKvORFYLsFzn40',
		// shippingTotal: (0).toFixed(2),
		// shippingPriceId: 'price_1OCm53DaV1AKvORFRavYc9RU',
		subtotal: 0,
		total: 0,
	};
};
export const flavorsReducer = createSlice({
	name: 'flavors',
	initialState: getInitialState(),
	reducers: {
		addToCart: (state, action) => {
			state.flavors[state.selectedIndex].quantity += action.payload;
			state.selectedFlavor = state.flavors[state.selectedIndex];
		},
		calculateTotals: (state, action) => {
			const shippingTotal = state.flavors
				.filter((flavor) => {
					return flavor.quantity > 0;
				})
				.reduce((sum, item) => sum + item.quantity, 0);

			if (shippingTotal > 2) {
				state.shippingPriceId = 'price_1NMvWaDaV1AKvORFlk543ld2';
				state.shippingTotal = (10).toFixed(2);
			} else {
				state.shippingPriceId = 'price_1NMvWMDaV1AKvORFYLsFzn40';
				state.shippingTotal = (5).toFixed(2);
			}

			// // Free shipping!
			// state.shippingPriceId = 'price_1OCm53DaV1AKvORFRavYc9RU';
			// state.shippingTotal = (0).toFixed(2);

			state.subtotal = state.flavors
				.filter((flavor) => {
					return flavor.quantity > 0;
				})
				.reduce((sum, item) => sum + item.quantity * item.price, 0.0)
				.toFixed(2);

			state.total = (
				parseFloat(state.shippingTotal) + parseFloat(state.subtotal)
			).toFixed(2);
		},
		decreaseQuantity: (state, action) => {
			const amount = action.payload.amount;
			const flavorId = action.payload.flavor.priceId;
			const selectedFlavor = state.flavors.find(
				(flavor) => flavor.priceId === flavorId
			);
			if (selectedFlavor.quantity - amount <= 0) {
				selectedFlavor.quantity = 0;
			} else {
				selectedFlavor.quantity -= amount;
			}
		},
		emptyCart: (state) => {
			state.selectedIndex = 0;
			state.selectedFlavor = FLAVORS[0];
			// state.shippingTotal = (0).toFixed(2);
			// state.shippingPriceId = 'price_1OCm53DaV1AKvORFRavYc9RU';
			state.shippingTotal = (5).toFixed(2);
			state.shippingPriceId = 'price_1NMvWMDaV1AKvORFYLsFzn40';
			state.subtotal = 0;
			state.total = 0;
			state.flavors.map((flavor) => (flavor.quantity = 0));
		},
		increaseQuantity: (state, action) => {
			const amount = action.payload.amount;
			const flavorId = action.payload.flavor.priceId;
			const selectedFlavor = state.flavors.find(
				(flavor) => flavor.priceId === flavorId
			);
			if (selectedFlavor.quantity + amount >= 10) {
				selectedFlavor.quantity = 10;
			} else {
				selectedFlavor.quantity += amount;
			}
		},
		moveCursorLeft: (state) => {
			if (state.selectedIndex - 1 >= 0) {
				const newIndex = state.selectedIndex - 1;
				state.selectedIndex = newIndex;
				state.selectedFlavor = state.flavors[newIndex];
			} else {
				const newIndex = state.flavors.length - 1;
				state.selectedIndex = newIndex;
				state.selectedFlavor = state.flavors[newIndex];
			}
		},
		moveCursorRight: (state) => {
			if (state.selectedIndex + 1 < state.flavors.length) {
				const newIndex = state.selectedIndex + 1;
				state.selectedIndex = newIndex;
				state.selectedFlavor = state.flavors[newIndex];
			} else {
				const newIndex = 0;
				state.selectedIndex = newIndex;
				state.selectedFlavor = state.flavors[newIndex];
			}
		},
		removeFromCart: (state, action) => {
			const flavorId = action.payload.flavor.priceId;
			const selectedFlavor = state.flavors.find(
				(flavor) => flavor.priceId === flavorId
			);
			selectedFlavor.quantity = 0;
		},
	},
});

// Action creators are generated for each case reducer function
export const {
	addToCart,
	calculateTotals,
	decreaseQuantity,
	emptyCart,
	increaseQuantity,
	moveCursorLeft,
	moveCursorRight,
	removeFromCart,
} = flavorsReducer.actions;

export default flavorsReducer.reducer;
