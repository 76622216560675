import { CircularProgress } from '@mui/material';
import Nav from '../ui/Nav';

export default function Loading() {
	return (
		<div className="LoadingScreen">
			<Nav />
			<CircularProgress
				sx={{
					color: '#F3FF4C',
				}}
			/>
		</div>
	);
}
