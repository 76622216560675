import * as React from 'react';
import { Link } from 'react-router-dom';
import './Nav.scss';
import Logo from '../assets/images/vaughts.png';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useLocation } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ReactComponent as Hamburger } from '../assets/images/hamburger.svg';
import { ReactComponent as Cart } from '../assets/images/cart.svg';

function Nav() {
	const [state, setState] = React.useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const flavors = useSelector((state) => state.flavorReducer.flavors);
	const numberItemsInCart = flavors
		.filter((flavor) => {
			return flavor.quantity > 0;
		})
		.reduce((sum, item) => sum + item.quantity, 0);

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const appDrawer = (anchor) => (
		<Box
			className="AppDrawer"
			onClick={toggleDrawer(anchor, false)}
			onKeyDown={toggleDrawer(anchor, false)}
		>
			<List>
				<ListItem
					key={'SHOP'}
					disablePadding
				>
					<ListItemButton
						component={Link}
						to={'/'}
					>
						<ListItemText
							sx={{
								'& .MuiTypography-root': {
									fontFamily: 'Gotham',
									fontSize: '1.25rem',
									letterSpacing: '0.04169rem',
								},
							}}
							primary="SHOP"
						/>
					</ListItemButton>
				</ListItem>
				<ListItem
					key={'ABOUT'}
					disablePadding
				>
					<ListItemButton
						component={Link}
						to={'/about'}
					>
						<ListItemText
							sx={{
								'& .MuiTypography-root': {
									fontFamily: 'Gotham',
									fontSize: '1.25rem',
									letterSpacing: '0.04169rem',
								},
							}}
							primary="ABOUT"
						/>
					</ListItemButton>
				</ListItem>
				<ListItem
					key={'CART'}
					disablePadding
				>
					<ListItemButton
						component={Link}
						to={'/cart'}
					>
						<ListItemText
							sx={{
								'& .MuiTypography-root': {
									fontFamily: 'Gotham',
									fontSize: '1.25rem',
									letterSpacing: '0.04169rem',
								},
							}}
							primary="CART"
						/>
					</ListItemButton>
				</ListItem>
			</List>
		</Box>
	);

	const LeftButton = () => {
		if (useLocation().pathname === '/details') {
			return (
				<IconButton
					className="LeftButton"
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					sx={{ mr: 2 }}
					component={Link}
					to={'/'}
				>
					<ArrowBackIcon className="NavIcon" />
				</IconButton>
			);
		} else {
			return (
				<IconButton
					className="LeftButton"
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					sx={{ mr: 2 }}
					onClick={toggleDrawer('left', true)}
				>
					<Hamburger className="NavIcon" />
				</IconButton>
			);
		}
	};

	return (
		<AppBar
			sx={{
				bgcolor: '#080451',
				'& .MuiButtonBase-root': {
					marginRight: '0',
				},
			}}
			className="NavBar"
			position="fixed"
		>
			<Drawer
				anchor={'left'}
				open={state['left']}
				onClose={toggleDrawer('left', false)}
			>
				{appDrawer('left')}
			</Drawer>
			<Toolbar className="NavBarToolBar">
				{LeftButton()}
				{/* <div className="LeftGroup">
					<Typography
						variant="h6"
						className="TextLink ShopButton"
						component={Link}
						to={'/'}
						sx={{ fontFamily: 'ChaparralProItalic' }}
					>
						SHOP
					</Typography>
					<Typography
						variant="h6"
						className="TextLink"
						component={Link}
						to={'/about'}
						sx={{ fontFamily: 'ChaparralProItalic' }}
					>
						ABOUT
					</Typography>
				</div> */}
				<Typography
					variant="h6"
					className="VaughtsLogo"
					component={Link}
					to={'/'}
					sx={{ flexGrow: 1 }}
				>
					<img
						className="VaughtsLogo"
						alt="Vaughts"
						src={Logo}
					/>
				</Typography>
				<IconButton
					size="large"
					edge="end"
					color="inherit"
					aria-label="menu"
					component={Link}
					to={'cart'}
				>
					<Badge
						sx={{
							'& .MuiBadge-badge': {
								color: 'white',
								backgroundColor: '#9091f6',
							},
						}}
						badgeContent={numberItemsInCart}
					>
						<Cart className="NavIcon" />
					</Badge>
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}

export default Nav;
