import './Home.css';
import Chocolate from '../components/Chocolate';
import { useSelector } from 'react-redux';

function Home() {
	const selectedFlavor = useSelector(
		(state) => state.flavorReducer.selectedFlavor
	);

	return (
		<div className="Home">
			<Chocolate flavor={selectedFlavor} />
		</div>
	);
}

export default Home;
