import './About.scss';
import Fruits from '../assets/images/fruits.jpg';
import Beans from '../assets/images/beans.jpg';
import Pen from '../assets/images/pen.png';
import Signature from '../assets/images/signature.png';

function About() {
	return (
		<div className="About">
			<div className="WhiteContainer">
				<img
					src={Pen}
					alt="PenImage"
					className="PenImage"
				/>
				<div className="PenTextSection">
					<div className="AboutTitle">To make the best I’ve ever had</div>
					<div className="AboutDescription">
						Some say dark chocolate is an acquired taste. Vaughts’ founder,
						Anna, spent years travelling the world trying every chocolate bar
						she found along the way, and she kept discovering the “best
						chocolate she had ever tasted” until her tastes surpassed what what
						she could find. She made her first chocolate bar hand roasting the
						beans over an open fire on a plantation in Central America.
						<br /> <br />
						Anna decided then that she would start her own company to make the
						best chocolate she had ever eaten. She brought a small bag of beans
						home to Texas with her and began making chocolate and sourcing
						ingredients until what she made surpassed anything she had ever
						tasted before. Now she gets to share it with you.
					</div>
					<div className="SignatureContainer">
						<img
							src={Signature}
							alt="SignatureImage"
							className="SignatureImage"
						/>
					</div>
					<div className="SignatureTitle">ANNA VAUGHT</div>
					<div className="SignatureSubtitle">Founder, Chocolatier</div>
				</div>
			</div>
			<div className="PinkContainer">
				<img
					src={Fruits}
					alt="Fruits"
					className="FruitsImage"
				/>
				<div>
					<div className="AboutPinkTitle">Eat chocolate for breakfast</div>

					<div className="AboutPinkDescription">
						The robustness of a good dark chocolate is best experienced first
						thing in the morning, before water, before coffee, before breakfast,
						when your taste buds still have a clean slate. Chocolate is a
						natural energizer since it contains iron, magnesium, copper,
						antioxidants, phenolics, and naturally occurring caffeine, making it
						perfect to wake up to. It is low in sugar, and high in antioxidants
						proven to reduce the risk for heart disease, so why not? Eat
						chocolate for breakfast. We do.
					</div>
				</div>
			</div>
			<div className="PinkContainerTwo">
				<img
					src={Beans}
					alt="Beans"
					className="BeansImage"
				/>
				<div>
					<div className="AboutPinkTitle AboutPinkTitleTwo">3 Ingredients</div>
					<div className="AboutPinkDescription">
						Vaughts chocolate starts with 3 ingredients, so those ingredients
						better be the best. We source and roast our cocoa beans from the
						best farms in the world. The farmers who grow, pick, and dry our
						beans by hand are paid fairly, and incorporate sustainable and
						biodiverse methods into their orchards. The flavor of the finished
						bar relies on the quality of the beans, the climate they were grown
						in, how they were dried, and the speces of tree. We hope to bring
						real dark chocolate to more people so they can discover a world of
						flavor profiles they didn’t know existed, from fresh and juicy, to
						mellow and nutty.
					</div>
				</div>

				<div className="AboutFinalSpacer"></div>
			</div>
		</div>
	);
}

export default About;
